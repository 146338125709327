:root {
  --fontFamily: Inter !important;
  --fontFamilykarla: karla !important;
}

.ListenerProfile {
  /* background-image: url("./ForCreative.png"); */
  background-color: black;
  /* height: 150vh; */
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 22px 0; */
}
.CreativeProfile {
  background-image: url("./ForCreative.png");
  background-color: black;
  height: 240vh;
  /* height: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 0;
}

.ListenerProfile img {
  /* width: 50px;
  height: auto;
  left: 0;
  top: 0;
  position: absolute;
  margin: 20px 35px; */
  /* border-radius: 50%; */
  /* height: 60px; */
  /* width: 90px;
  height: 90px; */
}
.inputs-row{
  font-family:var(--fontFamily) !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: white !important;
}
.upload-cover-icon{
  width: 40px;
    height: 40px;
    position: absolute !important;
    left: -17px !important;
}
.upload-font-stroke{
  font-family: var(--fontFamily) !important;
  font-size: 32px !important;
  font-weight: bold !important;
  color: #070707 !important;
  
  -webkit-text-stroke: 2px #fff !important;
  letter-spacing: 3.2px !important;
}

.align-stroke-left{
  text-align: left !important;
}
.align-stroke-center{
  text-align: center !important;
}

.with-stroke{
  color: white !important;
  font-family: var(--fontFamily) !important;
  font-size: 32px !important;
  font-weight: bold !important;
  -webkit-text-stroke: 2px #fff !important;
  letter-spacing: 3.2px !important;
}

.font-title-profile{
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: white !important;
}
.ant-select-selection-item{
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #8f92a1 !important;
}


.avatar_uploader .ant-upload.ant-upload-select-picture-card {
  width: 124px !important;
  height: 124px !important;
position: relative !important;
  background-color: transparent !important;
  border: 0px !important;
}
.avatar_uploader .ant-upload.ant-upload-select-picture-card > .ant-upload {
  height: 120px !important;
  width: 120px !important;
  border-radius: 50% !important;
  background-color: black !important;
  border: solid 3px #212121 !important;
  position: relative !important;
}
.avatar_uploader .ant-avatar {
  width: 124px !important;
  height: 124px !important;
  position: relative !important;
}
.avatar_outline {
  font-size: 27px;
  color: white;
  height: 100%;
  width: 100%;
  border: solid 3px #212121;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-position: center !important;
  object-fit: cover !important;
}
.upload_outline {
  border: solid 3px #212121 !important;
  height: 100px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-style: dashed; */
  border-radius: 15px;
}
.update-profile {
  background-color:black;
  border-radius: 20px;
  padding: 10px 0;
  margin: auto;
  width: 100vw;
  height: 100% !important;
  overflow-y: scroll;
}

.objectfit-cover{
  object-fit: cover;
}

.update-profile-audio {
  background-color: rgb(24, 24, 24);
  border-radius: 20px;
  padding: 10px 0;
  margin: auto;
  width: 100vw;
  height: 70vh;
  overflow-y: scroll;
}

.update-profile h1 {
  color: white !important;
  font-size: 26px;
  margin: auto;
  text-align: start;
  margin-left: 60px;
}
.upload-profile {
  /* text-align: center; */
  /* margin: auto; */
}
.upload-profile i {
  /* text-align: center; */
  /* align-items: center; */
  border-radius: 50%;
  /* padding: 40px 44px; */
  background-color: rgb(44, 44, 44);
  border: 1px solid white;
  color: white;
}
.select_genera .ant-select {
  /* width: 100% !important; */
  color: white !important;
  border-bottom: 1px solid #7a7e8a !important;
}
.ant-select-selector:focus-visible {
  border: none !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  /* display: none !important; */
  box-shadow: none !important;
}
/* .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select:not(.ant-select-customize-input).ant-select-selector {
  /* border: none !im; */
/* } */
/* .select_genera .ant-select-single:not(.ant-select-customize-input).ant-select-selector{
  padding: 0px !important;
  background-color:transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    border-bottom: 1px solid #7a7e8a  !important;
} */
.select_genera
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
  /* border-bottom: 1px solid #7a7e8a  !important; */
}

.select_genera .ant-select .ant-select-arrow {
  color: #3959f9 !important;
  font-size: 11px !important;
  font-weight: 800 !important;
  right: 0px !important;
}
.ant-select-multiple .ant-select-selection-item {
  background: none !important;
  color: white !important;
  border: none !important;
  -webkit-padding-start: 0px !important;
  padding-inline-start: 0px !important;
  -webkit-padding-end: 0px !important;
  padding-inline-end: 0px !important;
}
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  height: initial !important;
  flex-wrap: wrap !important;
  /* max-width: 100%;
}
.ant-select-multiple .ant-select-selection-item-remove{
  /* opacity: 0 !important; */
}
.profile-name {
  padding: 35px 0px;
  /* text-align: justify; */
}
.profile-name h2 {
  font-size: 18px;
  /* font-weight: lighter; */
  color: white;
}
.profile-name p {
  color: rgb(119, 119, 119);
}
.coverImg_uploader .ant-upload.ant-upload-select-picture-card {
  width: 80% !important;
  /* height: 100px !important; */
  height: 120px !important;
  margin-right: 0px !important;
  object-fit: fill;
}
.coverImg_uploader .ant-upload.ant-upload-select-picture-card {
  border: 0px !important;
  border-radius: 15px !important;
}
.react_video_player {
  border-radius: 10px !important;
}
.react_video_player .player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react_video_player .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.select_drop .ant-select-dropdown {
  background-color: #232323 !important ;
}
.select_drop .ant-select-item {
  padding: 0px !important;
}
.select_drop .ant-select-item-option-content {
  padding: 8px !important;
  background-color: #232323 !important ;
}
/* .choose_playlist  .ant-select-selection-item{
  margin-bottom: 7px !important; 
  height: 32px !important;
} */
.choose_playlist
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px !important;
}
.choose_playlist
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  white-space: initial !important;
}
.choose_playlist .ant-select-multiple .ant-select-selection-item {
  margin-bottom: 7px !important;
  height: 32px !important;
}
.choose_playlist .ant-select-multiple .ant-select-selection-item-content {
  white-space: initial !important;
}
.coverImg_uploader .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background-color:black !important;
  border-radius: 13px !important;
  border: solid 3px #212121 !important;
  position: relative !important;
  
}
.upload-cover {
  border: 1px solid white;
  border-style: dashed;
  border-radius: 15px;
  text-align: center;
  justify-items: center;
  width: 80%;
  background-color: rgb(48, 48, 48);
  height: 100px;
  margin: auto;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.upload-cover h2 {
  font-weight: lighter;
  font-size: 18px;
  margin: auto;
  color: white;
}
.inputs {
  padding: 10px 50px;
  text-align: center;
  /* margin: auto; */
}
.inputs p {
  color: rgb(119, 119, 119);
  font-size: 12px;
  text-align: start;
  margin-bottom: 0px;
}
.inputs input {
  background-color: none !important;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border-bottom: 1px solid rgb(129, 129, 129); */
  border-bottom: 1px solid #c2c3c6;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  /* padding: 7px 0 !important; */
  color: #8f92a1 !important;
}

.inputs input::placeholder {
  font-weight: bold;
  font-size: 14px;
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.inputs button {
  background-color: rgb(48, 48, 48);
  border: none;
  border-radius: 30px;
  padding: 5px 15px;
  letter-spacing: 2px;
  margin: 10px 5px;
  /* width: 100px; */
  cursor: pointer;
  font-family:var(--fontFamily) !important;
  font-size: 13px !important;
  font-weight: bold !important;
  text-transform: lowercase !important;
  color: #8f92a1 ;
}

.inputs button:first-letter {
  text-transform: uppercase !important;
}
.listener-profile-button {
  text-align: center;
  position: absolute !important;
  /* bottom: 10% !important; */
  left: 12% !important;
  width: 26% !important;
  /* top: 90% !important; */
}
.listener-profile-button button {
  background-color: red;
  color: white;
  width: 80%;
    /* height: 40px; */
    height: 50px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;
  margin: 10px 0;
  font-weight: 900 !important;
}
.creative-profile-button {
  text-align: center;
}
.creative-profile-button button {
  background-color: red;
  color: white;
  width: 40%;
  height: 40px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px 0;
}
.profile-back-btn {
  text-align: left;
  margin: 20px;
}

.profile-back-btn button {
  text-align: center;
  background-color: transparent !important;
  color: #8b8b8b !important;
  /* width: 50px; */
  border: none;
  border-radius: 20%;
  margin-left: 10px;
  cursor: pointer;
}
.profile-back-btn span {
  padding: 0px 6px 4px 0px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  text-align: justify !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
  left: 0px !important;
}

.image-upload-icon{
  width: 40px;
  height: 40px;
  position: absolute !important;
  left: 0 !important;
}

.ml-1-profile {
  margin-left: 1rem !important;
}
@media screen and (max-width: 1024px) {
  .upload-profile {
    /* text-align: center; */
    padding: 0 !important;
    /* margin-top: 50px; */
  }
  .profile-name {
    /* text-align: justify; */
    margin-top: 0px !important;
    padding-bottom: 0 !important;
  }
  .update-profile h1 {
    text-align: center;
    margin-bottom: 15px !important;
  }
  .ListenerProfile {
    padding: 0 0 !important;
    width: 100vw;
  }
  .CreativeProfile {
    padding: 0 0 !important;
  }
  .creative-profile-button button {
    width: 85%;
  }
  .inputs input {
    padding: 0;
  }
  .inputs p {
    /* margin-top: 10px; */
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 575px)  {
  .upload-avatar-profile{
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
/* .align-stroke-center{ */
  /* text-align: left !important; */
/* } */
.align-stroke-left{
  text-align: center !important;
}
.justify-center-listener-creative{
  justify-content: center !important;
}

}
@media screen and (max-width: 768px) {
  .profile-name {
    /* text-align: center; */
    margin: auto;
  }
  .inputs input {
    padding: 0;
  }
  .inputs p {
    /* margin-top: 10px; */
    margin-bottom: 0px;
  }
  .update-profile h1 {
    text-align: center;
    padding: 0;
    margin-left: 0;
  }
 

  .align-left-upload-text{
    text-align: left !important;
  }

  .ml-1-profile{
    margin-left: 0rem !important;
  }
}
@media screen and (max-width: 425px) {
  .profile-name {
    /* text-align: center; */
    margin: auto;
    margin-top: 10px !important;
  }
}

@media screen and  (max-width: 960px) {
  .listener-profile-button {
    position: unset !important;
     bottom: 0% !important;
     left: 0% !important;
  }
}
  @media screen and  (min-width: 960px) and (min-height: 590px) and (max-height: 699px) {
    .listener-profile-button {
      position: absolute !important;
       bottom: 6% !important;
    }
  }

  @media screen and  (min-width: 960px) and (min-height: 700px) and (max-height: 899px) {
    .listener-profile-button {
      position: absolute !important;
       bottom: 9% !important;
    }
  }

  @media screen and  (min-width: 960px) and (min-height: 900px) and (max-height: 1099px) {
    .listener-profile-button {
      position: absolute !important;
       bottom: 11% !important;
    }
  }

  @media screen and  (min-width: 960px)  and (min-height: 1100px) and (max-height: 1299px) {
    .listener-profile-button {
      position: absolute !important;
       bottom: 14% !important;
    }
  }

  @media screen and  (min-width: 960px)  and (min-height: 1300px)  {
    .listener-profile-button {
      position: absolute !important;
       bottom: 15% !important;
    }
  }

  
  @media screen and ( max-height: 600px ) {
    .listener-profile-button {
     
       bottom: 10% !important;
    }
  }

  .css-b62m3t-container {
    border: none !important;
  }


  .css-13cymwt-control {
      background-color: #070707 !important;
      border: none !important;
      border-radius: 0px !important;
     border-bottom: 1px solid rgb(62 63 65) !important;
     width: 100%;
     min-height: 35px !important;

  }

  .css-1hb7zxy-IndicatorsContainer {
    /* display: none !important; */
  }

  .css-1u9des2-indicatorSeparator{
     display: none !important;
  }

  .css-15lsz6c-indicatorContainer{
    color:#3959f9 !important
  }
  
  .css-1xc3v61-indicatorContainer{
    color:#3959f9 !important
}

  .css-1jqq78o-placeholder {
    display: none !important;
  }

  .css-t3ipsp-control:hover{
    border-color: transparent !important
  }

  .css-t3ipsp-control {
     box-shadow: none !important;
     border-color: transparent !important;
     width: 100% !important;
    background-color: #070707 !important;
    color: #8f92a1 !important;
   /*  border: none !important;
     border-radius: 0px !important;
     padding: 0px !important;
    display: flex !important;
    margin: 0px !important; */
  }

  .css-1dimb5e-singleValue {
    color: #8f92a1 !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }



  .css-1xc3v61-indicatorContainer{
    /* width: 100% !important; */
  }

  .css-1fdsijx-ValueContainer{
    /* background-color: #070707 !important;
     border: none !important;
     border-radius: 0px !important;
     padding: 0px !important; */
  }

  .css-qbdosj-Input {
    /* background-color: #070707 !important;
    color: white;
    padding: 0px !important;
    display: flex !important;
    margin: 0px !important;
    grid-area: none !important; */
  }
