.Frame-134 {
    display: flex;
    width: 300px;
    height: 150px;
    margin: 224px 173px 15px 195px;
    padding: 59px 82px 59px 81px;
    border-radius: 30px;
    border: solid 5px #141414;
    background-color: var(--color-01-100);
  }

  .Frame-135 {
    display: flex;
    width: 456px;
    height: 261px;
    /* margin: 100px 173px 15px 195px; */
    /* padding: 59px 82px 59px 81px; */
    margin-top: 100px;
    border-radius: 30px;
    border: solid 5px #141414;
    background-color: var(--color-01-100);
  }

  .Frame-Split {
    display: flex;
    width: 335px;
    height: 275px;
    /* margin: 100px 173px 15px 195px; */
    /* padding: 59px 82px 59px 81px; */
    margin-top: 100px;
    border-radius: 30px;
    border: solid 5px #141414;
    background-color: var(--color-01-100);
  }

  .frame-135-video-recognize{
    width: 456px;
  }
  
  .Frame-135-audio {
    display: flex;
    width: 261px;
    height: 261px;
    /* margin: 100px 173px 15px 195px; */
    /* padding: 59px 82px 59px 81px; */
    margin-top: 100px;
    border-radius: 30px;
    border: solid 5px #141414;
    background-color: var(--color-01-100);
  }

  .UploadMainScroll {
    overflow-x: hidden;
    overflow-y: auto;
}

.UploadMainScroll::-webkit-scrollbar-thumb {
    background-color: red!important;
  }
  
.UploadMainScroll::-webkit-scrollbar-track {
  background: #191919 !important;
  border-radius: 10px !important
}

.UploadMainScroll::-webkit-scrollbar{
   width: 10px;
   height: 0px !important;
}

  .drag_and_drop_padding-audio{
    padding-top: 30%;
  }

  .drag_and_drop_padding-video{
    padding-top: 20%;
  }

  .drag_and_drop_padding-video-selected{
    padding-top: 0%;
  }

.margin_top-audio{
  margin-top: 0px;
}

@media screen and (min-width: 1280px){
  .margin_top-audio{
    margin-top: -100px;
  }
}

.audio {
    width: 95px;
    height: 22px;
    margin: 5px 0 5px 10px;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    text-align: left;
    color: var(--color-2-5);
  }

  .justify-audio{
    /* align-items: center; */
    justify-content: center;
  }

  .height-width-video{
    width: 456px;
    height: 261px;
  }

  @media screen and (max-width: 500px) {
    .height-width-video{
      width: 300px;
      height: 261px;
    }
  }

  .height-width-audio{
    width: 261px;
    height: 261px;
  }

  .track-details-div{
    flex-direction: row;
  }

  @media screen and (max-width: 1279px) {
    .track-details-div{
      flex-direction: column;
    }
  }

  .Video {
    width: 94px;
    height: 22px;
    margin: 5px 0px 5px 10px;
    font-family: 'Inter',sans-serif;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    text-align: left;
    color: var(--color-2-5);
  }
.name-font-weight{
  font-weight: 900;
}

.buttons-font-weight{
  font-weight: 800;
}

.name-position{
  left: 100px;
}

.ant-progress-bg {
  height: 14px !important;
}

.upload-cover-margin{
  margin-top: 200px;
}

.ant-progress-inner {
  background-color: #141414;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: blue;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .name-position{
    left: 65px;
  }
}

@media screen and (min-width: 1400px) {
  .name-position{
    left: 100px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .name-position{
    left: 75px;
  }
}

@media screen and (min-width: 450px) and (max-width: 500px) {
  .name-position{
    left: 50px;
  }
}

@media screen and (min-width: 350px) and (max-width: 450px) {
  .name-position{
    left: 30px;
  }
}

@media screen and (min-width: 300px) and (max-width: 350px) {
  .name-position{
    left: 10px;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
   .main-track-name-position{
     left: 35px;
   }
}

.margin-left-name-div{
  margin: 18px 0 0 57px;
}

@media screen and (min-width: 300px) and (max-width: 430px) {
  .margin-left-name-div{
     margin: 5px;
  }
}


  .main-div{
    display: flex;
    width: 100%;
  }

  @media screen and (max-width: 1279px) {
    .main-div{
      flex-direction: column;
      align-items: center;
    }
  }

  .main-track-div{
    display: flex;
    width: 100%;
  }

  @media screen and (max-width: 1079px) {
    .main-track-div{
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 1080px) and (min-width: 1279px) {
    .img-grid-width{
      width: 50%;
    }
  }


  .margin-left-main{
     margin-left: 5px;
  }

  @media screen and (min-width: 1280px) {
    .margin-left-main{
      margin-left: 150px;
   }
  }


  .flex-direction-on-medium{
    flex-direction: row;
  }

  @media screen and (min-width: 500px) and (max-width: 1279px) {
    .flex-direction-on-medium{
      flex-direction: column;
    }
  }

  @media screen and (min-width: 1500px) {
    .flex-direction-on-medium{
      flex-direction: column;
    }
    .upload_scan_gap{
     margin-bottom: 100px !important;
    }
  }

.Mui-focused {
    border-bottom: 1px solid #030303 !important;
}

.button-upload-types{
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-radius: 20px;
  padding: 3px 20px;
  margin: 3px 3px;
  text-transform: capitalize;
  border-color: rgba(0, 3, 3, 0);
}


.public-private-buttons{
  padding: 10px;
  background-color: rgb(44, 43, 43);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button1_private {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  margin-right: 5px;
  width: 50%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
}


/* className changed */
.upload-content{
  margin: 0px;
  /* background-color: black; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}

.button2_public {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  width: 50%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 800;
}
  /* @media screen and (min-width: 747px) and (max-width: 959px) {
    .flex-direction-on-medium{
      flex-direction: column;
    }
  } */


  .search_track_to_link .ant-input-affix-wrapper {
    border-radius: 20px;
    background-color: rgb(40, 40, 40);
    border: 2px solid rgb(40, 40, 40);
  }
  .search_track_to_link .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .search_track_to_link
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none !important;
  }
  .search_track_to_link .ant-input-affix-wrapper > input.ant-input {
    background-color: rgb(40, 40, 40) !important;
    color: white !important;
  }

  .search_Link_icon{
    font-size: 16px;
  }

  .download-name-font{
    font-family: 'Inter',sans-serif;
    font-size: 32px;
    font-weight: 800;
  }

  .preference-charge-level{
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 900;
    color: white;
  }

  .splits-username .MuiInputBase-root{
    color: aliceblue;
  }

  /* .trackSplit_input .ant-input {
    background-color: rgb(26, 27, 31) !important;
    border-color: rgb(26, 27, 31) !important;
    border-bottom: 1px solid #7a7e8a !important;
    color: white !important;
    padding: 4px 0px !important;
    width: 100% !important;
  }
  
  . .ant-input::placeholder {
    padding: 0px !imptrackSplit_inputortant;
    color: #bfbfbf !important;
  }
  
  .trackSplit_input .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
  }
  
  .trackSplit_input .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  
  .trackSplit_input
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }
  
  .trackSplit_input .ant-form-item-explain-error {
    text-align: start !important;
    text-align: left !important;
    /* margin-top: -8px !important; */
  /* } */
   
  /* .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
      border-bottom: 1px solid #7a7e8a !important
    } */
  /* .trackSplit_input .ant-form-item-explain,
  .ant-form-item-extra {
    color: white !important;
    text-align: end !important;
    margin-top: 0px !important;
    cursor: pointer !important;
    align-self: start !important;
  }
  
  .trackSplit_input .ant-input-affix-wrapper {
    background-color: #17181c !important;
    border-color: #17181c !important;
    border-bottom: 1px solid #7a7e8a !important;
    padding: 0px !important;
  }
  
  .trackSplit_input .ant-input-affix-wrapper > input.ant-input {
    border-bottom: none !important;
  }
  
  .trackSplit_input .ant-form-item-label > label {
    color: #8f92a1 !important;
  } */

  .trackSplit_input .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #141414 !important;
    border-color: #141414 !important;
    /* border-bottom: #7a7e8a !important; */
  }

 .trackSplit_input .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input  {
    border-bottom: #7a7e8a !important;
  }

  .trackSplit_Userinput .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #141414 !important;
    border-color: #141414 !important;
    /* border-bottom: #7a7e8a !important; */
  }

 .trackSplit_Userinput .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input  {
    border-bottom: #7a7e8a !important;
  }

  .trackSplit_Userinput .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    /* padding: 0px; */
    height: 40px;
    margin-left: 40px;
    width: 85%;
  }

  .splits-placeholder .MuiInputBase-input {
    color: #7a7e8a; /* Change placeholder color here */
  }

  .custom-select-ant-username .ant-select-arrow {
    /* Your custom styles for the select arrow */
    left: 0;
    margin-left: -5px;
}
  
  .trackSplit-parentdiv {
    padding: 12px 17px 15px 16px;
    border-radius: 8px;
    background-color: rgb(26, 27, 31);
    width: 587px;
    min-height: 260px;
  }

  .place_holder_color .ant-select-selection-placeholder{
    color: #6d6969;
    font-size: 12px;
  }

  .flex-direction-splits{
    flex-direction: row;
  }

  .role-width{
    width: 70%;
  }

  .splits-width{
    width: 30%;
  }

  .colour-select-role 
  .ant-select.ant-select-in-form-item {
    color: white;
    font-weight: 900;
  }

  .value-label{
    color: #686262;
    text-align: left;
    margin-left: 50px;
    height: 15px;
    font-size: 12px;
}
  
.flex-direction-grid{
  flex-direction: column;
}

  @media screen and (max-width: 430px) {
    .flex-direction-splits{
      flex-direction: column;
    }

    .flex-direction-grid{
      flex-direction: row;
    }

    .role-width{
      width: 100%;
    }
  
    .splits-width{
      width: 100%;
    }

    .Frame-Split {
       height: 335px;
    }
  }



@media screen and (max-width: 1279px) {
  .hide-side-image-in-responsive{
       display: none !important;
  }
}
 
@media screen and (max-width: 1079px) {
  .hide-side-image-in-Main-responsive{
       display: none !important;
  }
}

@media screen and (min-width: 1080px) {
  .hide-buttons-in-large-Main-screens{
      display: none !important;
  }
}

@media screen and (min-width: 1280px) {
  .hide-buttons-in-large-screens{
      display: none !important;
  }
}

.dialog-Paper {
  background-color: #121212 !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%) !important;
  border: solid 1px #1f1f1f !important;
  width: 618px !important;
}

@media screen and (max-width: 1279px){
   .responsive-margin{
    margin-top: 100px;
   }
}

.username-margin{
  margin-top: 25px;
}

@media screen and (max-width: 430px) {
  .responsive-split-user-margin{
    margin-top: -50px;
  }
}

.selling-preference-margin{
  margin-top: 40px;
}

@media screen and (max-width: 1279px) {
  .selling-preference-margin{
    margin-top: 15px;
  }
  .link-search-margin{
    margin-bottom: 15px;
  }

  .cover-margin-top{
    margin-top: 20px;
  }
}

.link-margin{
  margin-top: 100px;
}

@media screen and (max-width: 500px) {
  .link-margin{
    margin-top: 15px;
  }
}

.track-details-margin{
  margin-top: 75px;
}

@media screen and (max-width: 500px) {
  .track-details-margin{
    margin-top: 15px;
  }
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .track-details-margin{
    margin-top: 25px;
  }
}

.main-track-margin{
  margin-top: 200px;
}

@media screen and (min-width: 700px) and (max-width: 1079px) {
  .main-track-margin{
    margin-top: 100px;
  }
}

@media screen and (max-width: 699px) {
  .main-track-margin{
    margin-top: 50px;
  }
}

.track-details-div-width{
  width: 100%
}

@media screen and (max-width: 599px) {
   .release-date-margin{
     margin-bottom: 10px;
   }
  
}


.splitUserNameWithMarginTop{
  margin-top: 10px !important
}

.splitUserNameWithOutMarginTop{
  margin-top: 25px !important;
}


@media screen and (max-width: 430px) {
  .split-margin-top{
    margin-top: 15px;
  }
  
  .splitUserNameWithMarginTop{
    margin-top: 50px !important
  }
  
  .splitUserNameWithOutMarginTop{
    margin-top: 60px !important;
  }
}



